<template>
    <div class="modal-select-reason">
        <ul class="reason-content m-l-20 m-r-20">
            <div
                class="title f-20 m-b-20"
                style="color: #181f39; line-height: 1.4; margin-top: 3.125rem; font-weight: 700"
            >
                {{ options.user.name[0] }}OO님의 외모는 어떤가요?
            </div>
            <li class="list f-13 m-l-20" style="list-style-type: disc; color: #666b70; line-height: 1.6">
                솔직하게 평가하면 더 잘 맞는 프로필을 전달해드릴 수 있어요!
            </li>
            <li class="list f-13 m-l-20" style="list-style-type: disc; color: #666b70; line-height: 1.6">
                선택 결과는 상대방에게 공개되지 않으며, 수락/거절에 영향을 미치지 않습니다.
            </li>
        </ul>
        <div class="user-detail-photos">
            <Carousel
                :per-page="1"
                :pagination-position="'bottom-overlay'"
                :pagination-size="8"
                :pagination-padding="4"
                :pagination-active-color="'#FFFFFF'"
                :pagination-color="'#808080'"
                :navigateTo="[initialPage, true]"
            >
                <Slide class="p-relative" :key="photo.id" v-for="photo in photos">
                    <!-- <div class="overlay top"></div> -->
                    <div class="img-cover" style="position: relative" v-img-cover="photo.url" />
                    <!-- <div class="overlay bottom"></div> -->
                </Slide>
            </Carousel>
        </div>
        <div class="btn-wrap">
            <div class="score-buttons m-b-6" v-for="item in scoringItems" :key="item.id" @click="onClickBtn(item)">
                <button class="score-btn" :class="{ active: item.$$selected }">
                    <img
                        class="face-emoji m-r-6"
                        v-if="item.$$selected"
                        :src="item.srcActive"
                        alt="face-icon"
                        width="24px"
                    />
                    <img class="face-emoji m-r-6" v-else :src="item.src" alt="face-icon" width="24px" />
                    <div class="text-box">{{ item.description }}</div>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import userEvaluationService from '@/services/user-evaluation'

export default {
    name: 'ModalAppearanceScore',
    props: ['options'],
    data: () => ({
        selectedValue: null,
        initialPage: 0,
        scoringItems: [
            {
                id: 1,
                description: '정말 마음에 들어요',
                src: require(`@/assets/images/icons/ri_emoticon-happy_inactive.png`),
                srcActive: require(`@/assets/images/icons/ri_emoticon-happy_active.png`),
                $$selected: false,
                score: 5,
            },
            {
                id: 2,
                description: '마음에 들어요',
                src: require(`@/assets/images/icons/ri_emoticon-happy-line_inactive.png`),
                srcActive: require(`@/assets/images/icons/ri_emoticon-happy-line_active.png`),
                $$selected: false,
                score: 4,
            },
            {
                id: 3,
                description: '보통이에요',
                src: require(`@/assets/images/icons/ri_emoticon-normal-line_inactive.png`),
                srcActive: require(`@/assets/images/icons/ri_emoticon-normal-line_active.png`),
                $$selected: false,
                score: 3,
            },
            {
                id: 4,
                description: '아쉬워요',
                src: require(`@/assets/images/icons/ri_emoticon-unhappy-line_inactive.png`),
                srcActive: require(`@/assets/images/icons/ri_emoticon-unhappy-line_active.png`),
                $$selected: false,
                score: 2,
            },
            {
                id: 5,
                description: '매우 아쉬워요',
                src: require(`@/assets/images/icons/ri_emoticon-unhappy_inactive.png`),
                srcActive: require(`@/assets/images/icons/ri_emoticon-unhappy_active.png`),
                $$selected: false,
                score: 1,
            },
        ],
        photos: [],
    }),
    computed: {
        me() {
            return this.$store.getters.me
        },
        isMe() {
            return this.userId === this.me.id
        },
    },
    async mounted() {
        await this.initPhoto()
    },
    methods: {
        async initPhoto() {
            setTimeout(() => {
                this.photos = this.options.userPhotos
            })
        },
        async onClickBtn(item) {
            if (item.$$selected) {
                this.$set(item, '$$selected', !item.$$selected)
                this.selectedValue = null
            } else {
                this.scoringItems.forEach(o => this.$set(o, '$$selected', false))
                this.$set(item, '$$selected', !item.$$selected)
                this.selectedValue = item

                setTimeout(async () => {
                    try {
                        const specScore = await this.$modal.custom({
                            component: 'ModalSpecScore',
                            options: {
                                chat: this.options.chat,
                                user: this.options.user,
                            },
                        })

                        const payload = {
                            user_id: this.me.id,
                            target_id: this.options.user.id,
                            appearance_score: item.score,
                            spec_score: specScore,
                        }

                        this.$loading(true)
                        const res = await userEvaluationService.post(payload)
                        this.$toast.success(res.msg)
                        this.$emit('close')
                        this.$loading(false)
                    } catch (e) {
                        this.$toast.error(e)
                    }
                }, 400)
            }
        },
    },
}
</script>
<style scoped lang="scss">
.user-detail-photos {
    position: relative;
    padding: 1.25rem 1.25rem 0.9375rem;

    .VueCarousel {
        margin-bottom: 0.5rem;

        .overlay {
            height: $header-height;

            &.top {
                top: 0;
                background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
            }

            &.bottom {
                top: initial;
                bottom: 0;
                background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2) 95%);
            }
        }

        .img-cover {
            width: 100%;
            padding-top: 100%;
            border-radius: 0.5rem;
        }

        ::v-deep .VueCarousel-pagination {
            display: initial;
            margin-bottom: 1rem;
        }
    }
}
.btn-wrap {
    margin: 0 1.25rem 0.9375rem;
    .score-buttons {
        height: 3rem;
        .score-btn {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 400;
            font-size: 0.875rem;
            color: #5c5c77;
            background-color: #e8e4e7;
            border: 1px solid #5c5c77;
            border-radius: 0.5rem;
            padding: 0.5rem;
            .text-box {
                width: 6.75rem;
                text-align: left;
            }

            &.active {
                background-color: #151360;
                color: #ffffff;
            }
        }
    }
}
</style>
